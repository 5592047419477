import { Column } from "primereact/column";
import {
  DataTable,
  DataTableSelectionSingleChangeEvent,
  DataTableValueArray,
} from "primereact/datatable";
import { useState } from "react";
import { useGetUsersQuery } from "../store/services/users";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { User } from "../models/users";
import { useUpdateEventHostMutation } from "../store/services/events";
import toast from "react-hot-toast";
import { InputFileCustom } from "./InputFileCustom";

interface SelectUsersProps {
  eventId: number;
  handleClose: () => void;
}

export const SingleSelectUser = ({
  eventId,
  handleClose,
}: SelectUsersProps) => {
  const [userEmail, setUserEmail] = useState<string>();
  const [userFirstName, setUserFirstName] = useState<string>();
  const [userLastName, setUserLastName] = useState<string>();
  const [userSelect, setUserSelect] = useState<User>();
  const [userDescription, setUserDescription] = useState("");
  const [imageBase64, setImageBase64] = useState("");
  const [imageFormat, setImageFormat] = useState("");
  const [fakeUserEmail, setFakeUserEmail] = useState<string>();
  const [fakeUserFirstName, setFakeUserFirstName] = useState<string>();
  const [fakeUserLastName, setFakeUserLastName] = useState<string>();

  const {
    data: usesrData,
    isLoading,
    refetch,
  } = useGetUsersQuery({
    email: userEmail,
    firstName: userFirstName,
    lastName: userLastName,
    userTypeId: 2,
  });

  
  const {
    data: fakeUsersData,
    isLoading: isLoadingFakeUsers,
    refetch: refetchFakeUsers,
  } = useGetUsersQuery({
    email: fakeUserEmail,
    firstName: fakeUserFirstName,
    lastName: fakeUserLastName,
    userTypeId: 3,
  });

  const [updateEventHost, { isLoading: isLoadingHost }] =
    useUpdateEventHostMutation();

  const handleAdd = () => {
    updateEventHost({
      id: eventId,
      hostId: userSelect?.id || 0,
      description: userDescription,
      imageFormat: imageFormat || null,
      imageBase64: imageBase64 || null,
    })
      .unwrap()
      .then(() => {
        toast.success("Host agregado al evento exitosamente!");
      })
      .catch(() => toast.error("No se pudo agregar el host al evento"))
      .finally(() => handleClose());
  };

  const handleInputFile = (value: string) => {
    const imageValues = value.split(",");
    setImageFormat(imageValues[0]);
    setImageBase64(imageValues[1]);
  };
  const handleInputFileClear = () => {
    setImageFormat("");
    setImageBase64("");
  };

  const disableSubmit =
    userDescription?.length === 0 ||
    !userSelect ||
    imageBase64?.length === undefined;

  const header = (
    <div className="grid mt-4">
      <div className="col-12 md:col-4 p-inputgroup">
        <span className="p-float-label">
          <InputText
            className="w-full"
            id="email"
            name="email"
            onChange={(e) => setUserEmail(e.currentTarget.value)}
            value={userEmail}
          />
          <label htmlFor="email">Email</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            setUserEmail("");
            refetch();
          }}
        />
      </div>
      <div className="col-12 md:col-4 p-inputgroup">
        <span className="p-float-label">
          <InputText
            className="w-full"
            id="lastName"
            name="lastName"
            onChange={(e) => setUserLastName(e.currentTarget.value)}
            value={userLastName}
          />
          <label htmlFor="lastName">Apellido</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            setUserLastName("");
            refetch();
          }}
        />
      </div>
      <div className="col-12 md:col-4 p-inputgroup">
        <span className="p-float-label">
          <InputText
            className="w-full"
            id="firstName"
            name="firstName"
            onChange={(e) => setUserFirstName(e.currentTarget.value)}
            value={userFirstName}
          />
          <label htmlFor="firstName">Nombre</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            setUserFirstName("");
            refetch();
          }}
        />
      </div>
    </div>
  );

   const fakeUsersHeader = (
     <div className="grid mt-4">
       <div className="col-12 md:col-4 p-inputgroup">
         <span className="p-float-label">
           <InputText
             className="w-full"
             id="email"
             name="email"
             onChange={(e) => setFakeUserEmail(e.currentTarget.value)}
             value={fakeUserEmail}
           />
           <label htmlFor="email">Email</label>
         </span>
         <Button
           icon="pi pi-times"
           className="p-button-danger"
           onClick={() => {
             setFakeUserEmail("");
             refetchFakeUsers();
           }}
         />
       </div>
       <div className="col-12 md:col-4 p-inputgroup">
         <span className="p-float-label">
           <InputText
             className="w-full"
             id="lastName"
             name="lastName"
             onChange={(e) => setFakeUserLastName(e.currentTarget.value)}
             value={fakeUserLastName}
           />
           <label htmlFor="lastName">Apellido</label>
         </span>
         <Button
           icon="pi pi-times"
           className="p-button-danger"
           onClick={() => {
             setFakeUserLastName("");
             refetchFakeUsers();
           }}
         />
       </div>
       <div className="col-12 md:col-4 p-inputgroup">
         <span className="p-float-label">
           <InputText
             className="w-full"
             id="firstName"
             name="firstName"
             onChange={(e) => setFakeUserFirstName(e.currentTarget.value)}
             value={fakeUserFirstName}
           />
           <label htmlFor="firstName">Nombre</label>
         </span>
         <Button
           icon="pi pi-times"
           className="p-button-danger"
           onClick={() => {
             setFakeUserFirstName("");
             refetchFakeUsers();
           }}
         />
       </div>
     </div>
   ); 

  return (
    <>
      <div className="flex justify-content-between align-items-center mb-2">
        <h1 className="text-xlg  m-0 p-0 ">
          Usuarios Reales
        </h1>
      </div>
      <DataTable
        value={usesrData}
        loading={isLoading}
        header={header}
        selection={userSelect}
        selectionMode={"radiobutton"}
        onSelectionChange={({
          value,
        }: DataTableSelectionSingleChangeEvent<DataTableValueArray>) => {
          setUserSelect(value ? (value as User) : undefined);
        }}
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column
          selectionMode={"single"}
          headerStyle={{ width: "3rem" }}
        ></Column>
        <Column field="email" header="Email"></Column>
        <Column field="lastName" header="Apellido"></Column>
        <Column field="firstName" header="Nombre"></Column>
      </DataTable>
      <div className="flex justify-content-between align-items-center mb-2">
        <h1 className="text-xlg  m-0 p-0 ">
          Usuarios Ficticios
        </h1>
      </div>
        <DataTable
          value={fakeUsersData}
          loading={isLoadingFakeUsers}
          header={fakeUsersHeader}
          selection={userSelect}
          selectionMode={"radiobutton"}
          onSelectionChange={({
            value,
          }: DataTableSelectionSingleChangeEvent<DataTableValueArray>) => {
            setUserSelect(value ? (value as User) : undefined);
          }}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
        >
          <Column
            selectionMode={"single"}
            headerStyle={{ width: "3rem" }}
          ></Column>
          <Column field="email" header="Email"></Column>
          <Column field="lastName" header="Apellido"></Column>
          <Column field="firstName" header="Nombre"></Column>
        </DataTable>
      {userSelect && (
        <>
          <h2 className="text-lg  mt-4 p-0 ">Coloque los datos del host</h2>
          <div className="flex flex-column align-items-center">
            <div className="flex flex-column align-items-center">
              <InputFileCustom
                handleInputFile={handleInputFile}
                handleInputFileClear={handleInputFileClear}
              />
            </div>
            <div className="col-12  my-4">
              <span className="p-float-label">
                <InputText
                  className="w-full"
                  id="description"
                  name="description"
                  onChange={(e) => setUserDescription(e.target.value)}
                  value={userDescription}
                  disabled={isLoadingHost}
                  maxLength={400}
                />
                <label htmlFor="address">Descripción</label>
              </span>
            </div>
          </div>
        </>
      )}
      <div className="flex justify-content-end mt-6">
        <Button
          icon="pi pi-plus"
          label="Agregar"
          onClick={handleAdd}
          disabled={disableSubmit}
          loading={isLoadingHost}
        />
      </div>
    </>
  );
};
