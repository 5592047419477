import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { NewEventMessage } from "../../models/events";
import { yupConstants } from "../../utils/constants/yup";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import toast from "react-hot-toast";
import {
  useNewEventMessageMutation,
  useUpdateEventMessageMutation,
} from "../../store/services/events-messages";

interface EventMessageFormProps {
  messageId?: number;
  eventId: number;
  userId: number;
  title?: string;
  message?: string;
  handleClose: () => void;
}

export const EventMessageForm = ({
  messageId,
  eventId,
  userId,
  title = "",
  message = "",
  handleClose,
}: EventMessageFormProps) => {
  const [newEventMessage, { isLoading: isLoadingNew }] =
    useNewEventMessageMutation();
  const [updateEventMessage, { isLoading: isLoadingUpdate }] =
    useUpdateEventMessageMutation();

  const { handleSubmit, touched, errors, values, handleBlur, handleChange } =
    useFormik({
      initialValues: {
        eventId,
        userId,
        title,
        message,
      } as NewEventMessage,
      validationSchema: Yup.object({
        title: Yup.string().required(yupConstants().required),
        message: Yup.string().required(yupConstants().required),
      }),
      onSubmit: (values) => {
        if (!!messageId) {
          updateEventMessage({
            id: messageId,
            title: values.title,
            message: values.message,
          })
            .unwrap()
            .then(() => {
              toast.success("Mensaje actualizado exitosamente!");
              handleClose();
            })
            .catch(() => toast.error("No se pudo actualizar el mensaje"));
        } else {
          newEventMessage(values)
            .unwrap()
            .then(() => {
              toast.success("Mensaje enviado exitosamente!");
              handleClose();
            })
            .catch(() => toast.error("No se pudo envíar el mensaje"));
        }
      },
    });
  return (
    <form className="grid pt-4" onSubmit={handleSubmit}>
      <div className="col-12 my-2">
        <span className="p-float-label">
          <InputText
            invalid={!!touched.title && !!errors.title}
            className="w-full"
            id="title"
            name="title"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            disabled={isLoadingNew || isLoadingUpdate}
            maxLength={16}
          />
          <label htmlFor="title">Titulo</label>
        </span>
        {touched.title && errors.title && (
          <small className="p-error">{errors.title}</small>
        )}
      </div>
      <div className="col-12 my-2">
        <span className="p-float-label">
          <InputTextarea
            invalid={!!touched.message && !!errors.message}
            className="w-full"
            id="message"
            name="message"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.message}
            disabled={isLoadingNew || isLoadingUpdate}
            maxLength={1000}
          />
          <label htmlFor="message">Mensaje</label>
        </span>
        {touched.message && errors.message && (
          <small className="p-error">{errors.message}</small>
        )}
      </div>
      <div className="col-12 flex justify-content-center gap-4">
        <Button
          icon="pi pi-check"
          severity="success"
          type="submit"
          label={"Envíar"}
          loading={isLoadingNew || isLoadingUpdate}
        />
        <Button
          icon="pi pi-times"
          severity="secondary"
          type="button"
          label={"Cancelar"}
          onClick={handleClose}
          loading={isLoadingNew || isLoadingUpdate}
        />
      </div>
    </form>
  );
};
