/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  useChangeEventStateMutation,
  useDeleteEventMutation,
  useGetEventsQuery,
} from "../../store/services/events";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { DataView } from "primereact/dataview";
import { EventFilters } from "../Filters/EventFilters";
import { Image } from "primereact/image";
import { Badge } from "primereact/badge";
import {
  correctDateAndHours,
  extractHoursAndMinutes,
  formatDateWithoutSeconds,
} from "../../utils/functions/date";
import { convertToPrice } from "../../utils/functions/currencies";
import { SplitButton } from "primereact/splitbutton";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { SelectUsers } from "../SelectUsers";
import { DuplicateEventForm } from "../Forms/DuplicateEventForm";
import { EventMessageForm } from "../Forms/EventMessageForm";
import { Event } from "../../models/events";
import { orderEventsPerDate } from "../../utils/functions/events";
import { SingleSelectUser } from "../SingleSelectUser";
import { format } from "date-fns";
import { es } from "date-fns/locale";

interface EventListDVProps {
  show?: "all" | "next";
}
let lastRenderedDate: string | null = null;
export const EventListDV = ({ show = "all" }: EventListDVProps) => {
  const navigate = useNavigate();

  const [eventId, setEventId] = useState<number>();
  const [eventTypeId, setEventTypeId] = useState<number>();
  const [eventSubTypeId, setEventSubTypeId] = useState<number>();
  const [startDate, setStartDate] = useState<string>();
  const [eventName, setEventName] = useState<string>("");
  const [limit, setLimit] = useState(10);
  const [eventToDelete, setEventToDelete] = useState<Event>();
  const [realUsersToAdd, setRealUsersToAdd] = useState<Event>();
  const [fictionalUsersToAdd, setFictionalUsersToAdd] = useState<Event>();
  const [hostToAdd, setHostToAdd] = useState<Event>();
  const [duplicateEvent, setDuplicateEvent] = useState<Event>();
  const [newEventMessage, setNewEventMessage] = useState<Event>();
  const {
    data,
    isLoading,
    refetch: refetchEvents,
  } = useGetEventsQuery({
    id: eventId,
    startDate,
    name: eventName,
    typeId: eventTypeId,
    subTypeId: eventSubTypeId,
    limit: limit,
    show,
  });

  const [deleteEvent, { isLoading: isLoadingDelete }] =
    useDeleteEventMutation();

  const [changeEventState, { isLoading: isLoadingEventState }] =
    useChangeEventStateMutation();

  const handleChangeEventState = (id: number) => {
    changeEventState(id)
      .unwrap()
      .then(() => {
        toast.success("Estado del evento cambiado exitosamente!");
        refetchEvents();
      })
      .catch(() => toast.error("No se pudo cambiar el estado del evento"));
  };

  const hideDeleteEventDialog = () => setEventToDelete(undefined);
  const hideAddRealUsersDialog = () => {
    setRealUsersToAdd(undefined);
    refetchEvents();
  };

  const hideAddFictionalUsersDialog = () => {
    setFictionalUsersToAdd(undefined);
    refetchEvents();
  };

  const hideAddHostDialog = () => setHostToAdd(undefined);
  const hideNewEventMessageDialog = () => setNewEventMessage(undefined);
  const hideDuplicateEventDialog = () => setDuplicateEvent(undefined);

  const handleDeleteEvent = (id: number) => {
    deleteEvent(id)
      .unwrap()
      .then(() => {
        toast.success("Evento eliminado exitosamente!");
        refetchEvents();
      })
      .catch(() => toast.error("No se pudo eliminar el evento"))
      .finally(() => hideDeleteEventDialog());
  };

  return (
    <>
      <DataView
        loading={isLoading || isLoadingDelete || isLoadingEventState}
        value={orderEventsPerDate(data) || []}
        paginator
        rows={5}
        emptyMessage="Sin eventos"
        header={
          <EventFilters
            eventId={eventId}
            setEventId={setEventId}
            startDate={startDate}
            setStartDate={setStartDate}
            eventName={eventName}
            setEventName={setEventName}
            eventTypeId={eventTypeId}
            setEventTypeId={setEventTypeId}
            eventSubTypeId={eventSubTypeId}
            setEventSubTypeId={setEventSubTypeId}
            limit={limit}
            setLimit={setLimit}
          />
        }
        
        listTemplate={(items: Event[]): React.ReactNode[] =>
          items.map((event, index) => {
            const validateDate = correctDateAndHours(event) < new Date();



            const eventDate = format(new Date(event.startDate).setHours(0, 0, 0, 0), "EEEE - dd/MM/yyyy", { locale: es });

            // Capitalize the first letter
            const formattedDate = eventDate.charAt(0).toUpperCase() + eventDate.slice(1);
          
            const showHeader = formattedDate !== lastRenderedDate;
            lastRenderedDate = formattedDate;
            
            return (
              <React.Fragment key={event.id}>
              {showHeader && (
                <h2 className="text-xxl font-bold my-4 text-primary">{formattedDate}</h2>
              )}

                <div
                  key={event.id}
                  className={`
                    col-12 flex flex-column lg:flex-row align-items-center p-2 gap-4
                      ${index !== 0 && "border-top-1 surface-border"}
                    `}
                >
                  <Image
                    src={`${
                      event.imageBase64
                        ? event.imageFormat + "," + event.imageBase64
                        : "/assets/images/Image-not-found.png"
                    }`}
                    height="170"
                    width="auto"
                    alt={event.name}
                  />
                  <div
                    className="cursor-pointer flex flex-column justify-content-between align-items-start flex-1 gap-2"
                    onClick={() =>
                      navigate(event.id ? `/events/${event.id.toString()}` : "")
                    }
                  >
                    <div className="flex flex-column align-items-start gap-2">
                      <div>
                        <h1 className="text-3xl font-bold text-900 p-0 m-0 flex flex-wrap align-items-center gap-2">
                          {event.name}{" "}
                          <span className="text-sm text-400 font-semibold">
                            (ID: #{event.id})
                          </span>
                          <Badge
                            className="hidden md:block"
                            value={`Cupos: ${event?.amount}`}
                          />
                          <Badge
                            className="hidden md:block"
                            value={`Disponibles: ${event?.available}`}
                            severity={"warning"}
                          />
                          <Badge
                            className="hidden md:block"
                            value={`Estado: ${
                              correctDateAndHours(event) < new Date()
                                ? "Finalizado"
                                : !!event?.active
                                ? "Activo"
                                : "Inactivo"
                            }`}
                            severity={
                              correctDateAndHours(event) < new Date()
                                ? "warning"
                                : !!event?.active
                                ? "success"
                                : "danger"
                            }
                          />
                          <Badge
                            className="hidden md:block"
                            value={`🤖 ${event?.fictional}`}
                          />
                          <Badge
                            className="hidden md:block"
                            value={`🧑 ${event?.users}`}
                          />
                        </h1>
                        <Badge
                          className="md:hidden"
                          value={`Cupos: ${event?.amount}`}
                        />
                        <Badge
                          className="md:hidden"
                          value={`Disponibles: ${event?.available}`}
                          severity={"warning"}
                        />
                        <Badge
                          className="md:hidden"
                          value={`Estado: ${
                            correctDateAndHours(event) < new Date()
                              ? "Finalizado"
                              : !!event?.active
                              ? "Activo"
                              : "Inactivo"
                          }`}
                          severity={
                            correctDateAndHours(event) < new Date()
                              ? "warning"
                              : !!event?.active
                              ? "success"
                              : "danger"
                          }
                        />
                        <Badge
                          className="md:hidden"
                          value={`🤖 ${event?.fictional}`}
                        />
                        <Badge
                          className="md:hidden"
                          value={`🧑 ${event?.users}`}
                        />
                        <h2 className="text-2xl font-medium text-900 p-0 m-0 white-space-nowrap overflow-hidden text-overflow-ellipsis w-12rem sm:w-30rem">
                          {event.description}
                        </h2>
                      </div>
                      <div className="flex flex-column md:flex-row align-items-start md:gap-3">
                        <span className="flex align-items-center gap-2 font-semibold">
                          <i className="pi pi-calendar-clock"></i>
                          {formatDateWithoutSeconds(event.startDate)}
                        </span>
                        <span className="flex align-items-center gap-2 font-semibold ">
                          <i className="pi pi-clock"></i>
                          Duración: {extractHoursAndMinutes(event?.duration)}hs
                        </span>
                      </div>
                      <div className="flex flex-column md:flex-row align-items-start md:gap-3">
                        <span className="flex align-items-center gap-2 font-semibold ">
                          <i className="pi pi-map"></i>
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${event.address}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary"
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                            {event?.address}
                          </a>
                        </span>
                        <span className="flex align-items-center gap-2 font-semibold ">
                          <i className="pi pi-map-marker"></i>
                          Espacio: {event.space}
                        </span>
                      </div>
                      <div className="flex flex-column md:flex-row align-items-start md:gap-3">
                        <span className="flex align-items-center gap-2 font-semibold underline">
                          Evento: {convertToPrice(event?.price || 0)}
                        </span>
                        <span className="flex align-items-center gap-2 font-semibold underline">
                          Inscripción: {convertToPrice(event.inscription)}
                        </span>
                      </div>
                      <div className="flex align-items-start gap-2">
                        <span className="flex align-items-center gap-1 font-semibold text-xs">
                          <i className="pi pi-tag text-xs"></i>
                          {event.eventTypeName}
                        </span>
                        <span className="flex align-items-center gap-1 font-semibold text-xs">
                          <i className="pi pi-tag text-xs"></i>
                          {event.eventSubTypeName}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex align-items-center justify-content-center">
                    <SplitButton
                      label="Acciones"
                      model={
                        !validateDate
                          ? [
                              {
                                label: "Editar",
                                icon: "pi pi-pencil",
                                command: () =>
                                  navigate(`/events/${event.id}/edit`),
                              },
                              {
                                label: "Enviar mensaje",
                                icon: "pi pi-send",
                                command: () => setNewEventMessage(event),
                              },
                              {
                                label: "Seleccionar host",
                                icon: "pi pi-user-plus",
                                command: () => setHostToAdd(event),
                              },
                              {
                                label: "Agregar usuarios reales",
                                icon: "pi pi-users",
                                command: () => setRealUsersToAdd(event),
                              },
                              {
                                label: "Agregar usuarios ficticios",
                                icon: "pi pi-users",
                                command: () => setFictionalUsersToAdd(event),
                              },
                              {
                                label: "Duplicar evento",
                                icon: "pi pi-clone",
                                command: () => setDuplicateEvent(event),
                              },
                              {
                                label: "Activar / Desactivar",
                                icon: "pi pi-eye-slash",
                                command: () => handleChangeEventState(event.id),
                              },
                              {
                                label: "Eliminar",
                                icon: "pi pi-times",
                                command: () => setEventToDelete(event),
                              },
                            ]
                          : [
                              {
                                label: "Duplicar evento",
                                icon: "pi pi-clone",
                                command: () => setDuplicateEvent(event),
                              },
                              {
                                label: "Eliminar",
                                icon: "pi pi-times",
                                command: () => setEventToDelete(event),
                              },
                            ]
                      }
                      outlined
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          })
        }
      />
      {eventToDelete && (
        <Dialog
          header="Eliminar evento"
          visible={!!eventToDelete}
          style={{ width: "50vw" }}
          onHide={hideDeleteEventDialog}
          footer={
            <div>
              <Button
                severity="danger"
                label="Eliminar"
                onClick={() => handleDeleteEvent(eventToDelete.id)}
              />
              <Button
                severity="secondary"
                label="Cancelar"
                onClick={hideDeleteEventDialog}
              />
            </div>
          }
        >
          <h1 className="text-3xl text-red-500 m-0 p-0">
            <i className="pi pi-exclamation-triangle"></i> ¡Cuidado!
          </h1>
          <p className="m-0 p-0">
            ¿Estas seguro que desea eliminar el evento{" "}
            <b>{eventToDelete?.name}</b>?
          </p>
        </Dialog>
      )}
      {realUsersToAdd && (
        <Dialog
          header="Agregar usuarios reales"
          visible={!!realUsersToAdd}
          style={{ width: "90vw" }}
          onHide={hideAddRealUsersDialog}
        >
          <SelectUsers
            mode="user"
            eventId={realUsersToAdd.id}
            handleClose={hideAddRealUsersDialog}
          />
        </Dialog>
      )}
      {fictionalUsersToAdd && (
        <Dialog
          header="Agregar usuarios ficticios"
          visible={!!fictionalUsersToAdd}
          style={{ width: "90vw" }}
          onHide={hideAddFictionalUsersDialog}
        >
          <SelectUsers
            mode="fictional"
            eventId={fictionalUsersToAdd.id}
            handleClose={hideAddFictionalUsersDialog}
          />
        </Dialog>
      )}
      {duplicateEvent && (
        <Dialog
          header="Duplicar evento"
          visible={!!duplicateEvent}
          style={{ width: "40vw" }}
          onHide={hideDuplicateEventDialog}
        >
          <DuplicateEventForm
            event={duplicateEvent}
            handleClose={hideDuplicateEventDialog}
          />
        </Dialog>
      )}
      {hostToAdd && (
        <Dialog
          header="Seleccionar host"
          visible={!!hostToAdd}
          style={{ width: "90vw" }}
          onHide={hideAddHostDialog}
        >
          <SingleSelectUser
            eventId={hostToAdd.id}
            handleClose={hideAddHostDialog}
          />
        </Dialog>
      )}
      {newEventMessage && (
        <Dialog
          header="Nuevo mensaje para el evento"
          visible={!!newEventMessage}
          style={{ width: "40vw" }}
          onHide={hideNewEventMessageDialog}
        >
          <EventMessageForm
            eventId={newEventMessage.id}
            userId={1}
            handleClose={hideNewEventMessageDialog}
          />
        </Dialog>
      )}
    </>
  );
};
